import React, { useState, useEffect, useContext } from "react";
import { createRoot } from 'react-dom/client'
import Datatables, { redrawDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { SOCI_MANAGE_BUSINESS_LIST, RESTORE_DELETED, CREATE_UPDATE_LOCATION_TICKET, USER_ASSIGN_LOCATION, fetchData } from "../../components/Service";
import * as Elements from "../../components/Elements";
import { Context } from "../../components/Context";
import $ from 'jquery';
import { Popover } from "bootstrap";
import { useNavigate, useLocation, useParams } from "react-router";
import _, { now } from "lodash";
import Select from "react-select";
import { dateTimeFormat, toastNotify, initialFormState, validateForm } from "../../components/Helper";
import SociLocationForm from "./SociLocationForm";

function ManageBusinessListing() {

    const navigate = useNavigate();
    const { type } = useParams();
    const [listingType, setListingType] = useState(type);
    const [context] = useContext(Context)
    const [editId, setEditId] = useState(false)
    const location = useLocation();
    const [refresh, setRefresh] = useState(false);
    const [reload, setReload] = useState(now);
    const [tinyloader, setTinyloader] = useState(false);
    const [ticketRecord, setTicketRecord] = useState(false)
    const [userEmail, setUserEmail] = useState([])
    const [userDefaultEmail, setUserDefaultEmail] = useState([])
    const [editRecord, setEditRecord] = useState('')

    useEffect(() => {

        if (location && location.state && location.state.prevPage === 'dashboard') {
            setTimeout(function () {
                $('button[data-bs-target="#locationModal"]').trigger('click');
            }, 100)
        }
    }, [location]);

    const ViewLocation = (records) => {

        !context.auth.soci_api_token
            ? navigate(`../view-soci-location/${records?.project_id}`)
            : window.open(`https://www.socialmedia.amazio.com/admin/account/1601/office/0/project/${records?.project_id}/listings_overview?api_key=${context.auth.soci_api_token}`);
    };

    const handleNavigate = (records) => {
        navigate(`../view-location-detail/${records?.project_id}`, {
            state: {
                records: records,
            }
          });
    }

    const [dt] = useState({
        dt_url: `${SOCI_MANAGE_BUSINESS_LIST}/${listingType}`,
        dt_name: 'location',
        dt_export: true,
        dt_render: () => {
            setTimeout(function () {
                Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
            }, 0)
        },
        dt_column: [
            { data: 'alias_name', name: 'alias_name', title: 'PC Number', class: "text-nowrap minw-150px" },
            { data: 'internal_name', name: 'internal_name', title: 'Internal Name (PSL)', class: "text-nowrap minw-150px" },
            { data: 'listings_name', name: 'listings_name', title: 'Business Name', class: "text-nowrap minw-150px" },
            { data: 'address', name: 'address', title: 'Address', class: "text-nowrap minw-100px" },
            { data: 'city', name: 'city', title: 'City', class: "text-center minw-80px" },
            { data: 'state', name: 'state', title: 'State', class: "text-center minw-80px" },
            { data: 'postal_code', name: 'postal_code', title: 'Zip', class: "text-center minw-80px" },
            { data: 'subscription', name: 'subscription', title: 'Subscription', class: "text-nowrap minw-150px" },
            { data: 'created_at', name: 'created_at', title: 'Date', class: "text-center minw-100px" },
            { data: 'opening_status', name: 'opening_status', title: 'Location Status', class: "text-center minw-100px" },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 0,
                createdCell: (td, cellData, records, row, col) => { createRoot(td).render( records && records.alias_name ? records.alias_name : '-') }
            },
            {
                targets: 9,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        records && records.opening_status ?
                            <span className={`badge rounded-pill bg-${records.opening_status === 'open' ? 'success' : records.opening_status === 'Verification Required' ? 'primary' : records.opening_status === 'temporarily_closed' ? 'warning' : records.opening_status === 'Code Requested' ? 'info' : 'danger'}`}>
                                {records && _.capitalize(_.replace(records.opening_status, '_', ' '))}
                            </span>
                            : 'N/A'
                    )
                }
            }, {
                targets: 10,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        records.deleted_at ? <span className="badge rounded-pill bg-primary cursor-pointer" onClick={() => restoreDeletedLocation(records.project_id)}>Restore</span> :
                            <div className="dropdown">
                                <button className="btn btn-light" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-three-dots-vertical"></i>
                                </button>
                                <ul className="dropdown-menu button-issues listingMenuToggle">
                                    <div className="d-flex justify-content-center align-items-center text-nowrap">
                                        {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ?
                                            context.auth.permission && context.auth.permission.map((item, key) => {
                                                return (
                                                    <div key={key}>
                                                        {item.permission === "Manage Location" &&
                                                            <>
                                                                {item.edit === "yes" ?

                                                                    <button type="button" className="btn btn-sm text-success" data-bs-toggle="modal" data-bs-target="#locationModal" onClick={() => { setEditId(true); setEditRecord(records) }}>
                                                                        <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                                    </button>

                                                                    :
                                                                    <Elements.DisableButton btnType="success" icon="#icon_edit" />
                                                                }
                                                            </>
                                                        }
                                                        {item.permission === "Business listing" &&
                                                            <>
                                                                {item.view === "yes" ?

                                                                    <button type="button" className="btn btn-sm text-primary" onClick={() => ViewLocation(records)}>
                                                                        <svg className="icon fs-5" role="img"><use href="#icon_eye" /></svg>
                                                                    </button>

                                                                    :
                                                                    <Elements.DisableButton btnType="primary" icon="#icon_eye" />
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                )
                                            })
                                            :
                                            <>
                                                <button type="button" className="btn btn-sm text-success" data-bs-toggle="modal" data-bs-target="#locationModal" onClick={() => { setEditId(true); setEditRecord(records) }}>
                                                    <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                </button>
                                                <button type="button" className="btn btn-sm text-primary" onClick={() => ViewLocation(records)}>
                                                    <svg className="icon fs-5" role="img"><use href="#icon_eye" /></svg>
                                                </button>
                                            </>
                                        }
                                        <button type="button" className="btn btn-sm text-dark" data-bs-target="#locationTicket" data-bs-toggle="modal" onClick={() => setTicketRecord(records)}>
                                            <i className="bi bi-chat-quote fs-5"></i>
                                        </button>
                                        <button type="button" className="btn btn-sm text-dark" onClick={() => handleNavigate(records)}>
                                            <i className="bi bi-reception-3 fs-5"></i> 
                                        </button>
                                    </div>
                                </ul>
                            </div>
                    )
                }
            }
        ]
    });

    const restoreDeletedLocation = (projectId) => {
        fetchData(RESTORE_DELETED, 'POST', { project_id: projectId }, true, false, (res) => {
            if (res.success) {
                setRefresh(now)
            }
        }, false, false, false)
    }

    window.resetLocationId = (data) => {
        setEditId(data)
        const locationModal = document.getElementById('locationModal');
        const closeButtons = locationModal.querySelectorAll('[data-bs-dismiss="modal"]'); // Get all close buttons
        closeButtons.forEach(button => {
            button.addEventListener('click', function () {
                initialFormState('locationForm')
                $('input[name="business_hour_from[]"]').val('');
                $('input[name="business_hour_to[]"]').val('');
                $(".invalid-custom-feedback").remove();
                $(".react-select").removeClass('is-invalid is-valid');
            });
        });
    }

    window.setListings = (type) => {
        setListingType(type)
    }

    useEffect(() => {
        setListingType(type);
    }, [type])

    useEffect(() => {
        reloadUrlDataTable(dt, `${SOCI_MANAGE_BUSINESS_LIST}/${listingType}`);
    }, [listingType])

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }

        if (!$('.dt-custom-filter button').hasClass('add-new')) {
            {
                if (context && context.auth) {
                    if (context.auth.user_group_id === 1 && context.auth.super_admin === "no") {
                        context.auth.permission?.forEach((item) => {
                            if (item.permission === "Manage Location" && item.add === "yes") {
                                $(".dt-custom-filter").append(`
                                    <button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" 
                                        id="location_close" data-bs-toggle="modal" data-bs-target="#locationModal" 
                                        onclick="resetLocationId(false)">
                                        <svg class="icon tscale-1p3 me-1" role="img">
                                            <use href="#icon_plus"></use>
                                        </svg> Add New
                                    </button>
                                `);
                            }
                        });
                    } else if (context.auth.role_id !== 3) {
                        $(".dt-custom-filter").append(`
                            <button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new"  
                                onclick="setListings('deleted')" title='Restore deleted'>
                                <svg class="icon" role="button"><use href="#icon_refresh"></use></svg>
                            </button>
                            <button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" 
                                id="location_close" data-bs-toggle="modal" data-bs-target="#locationModal" 
                                onclick="resetLocationId(false)">
                                <svg class="icon tscale-1p3 me-1" role="img">
                                    <use href="#icon_plus"></use>
                                </svg> Add New
                            </button>
                        `);
                    }
                }
            }
        }

    }, [dt, refresh, reload])

    const saveLocationTicket = (e) => {
        e.preventDefault()
        if (validateForm(e, 'locationTicketForm')) {
            setTinyloader(true)
            let formData = new FormData(document.getElementById('locationTicketForm'));
            formData.append('active_location_id', ticketRecord && ticketRecord.internal_name);

            fetchData(CREATE_UPDATE_LOCATION_TICKET, 'POST', formData, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    initialFormState('locationTicketForm')
                    setTicketRecord(false)
                    setReload(now());
                    document.querySelector('#locationTicket [data-bs-dismiss="modal"]').click();
                }
            });
        }
    }

    const selectUserEmail = (e) => {
        setUserDefaultEmail(e ?? false)
    }

    useEffect(() => {
        if (ticketRecord && ticketRecord.location_id) {
            fetchData(`${USER_ASSIGN_LOCATION}/${ticketRecord.location_id}`, 'GET', '', true, false, (res) => {
                if (res && res.records && res.records.length > 0) {
                    setUserEmail([...userDefaultEmail, ...res.records]);
                } else {

                    setUserEmail(userDefaultEmail)
                }
            });
        }

        setUserDefaultEmail([
            {
                "value": process.env.REACT_APP_AMAZIO_SALES_MAIL,
                "label": "Amazio Sales Team"
            },
            {
                "value": process.env.REACT_APP_AMAZIO_TECH_MAIL,
                "label": "Amazio Tech Team"
            },
            {
                "value": process.env.REACT_APP_AMAZIO_MEGAN_MAIL,
                "label": "Amazio Megan's Mail"
            }
        ])

    }, [ticketRecord])

    return (
        <>
            <Elements.ListSection title={`${listingType === '64332' ? 'Social Media Listing' : (listingType === '64331' ? 'Manage All Locations' : 'Deleted Locations')}`}>
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body">
                            <Datatables dt_name="location" dataPageLength="15" />
                        </div>
                    </div>
                </div>
            </Elements.ListSection>

            <Elements.ModalSection title={`${editId ? `Edit Location ${type === '64332' ? '(Social Media)' : '(Business Listing)'}` : `Add Location ${type === '64332' ? '(Social Media)' : '(Business Listing)'}`}`} modal_id="locationModal" size="xl">
                <SociLocationForm editId={editId} records={editRecord} refresh={setRefresh} group_id={type} setEditId={setEditId} setEditRecord={setEditRecord} />
            </Elements.ModalSection>

            <Elements.ModalSection title="Location Ticket" modal_id="locationTicket" size="xl" form_id="locationTicketForm" setData={''}>
                <form className="need-validation" noValidate id="locationTicketForm">
                    <div className="row">
                        <div className="col-md-5 border-end pe-3 pb-5" style={{ height: "650px" }}>
                            <div className="col-md-12 mt-3">
                                <label htmlFor="location_name">Location Name</label>
                                <input type="text" className="form-control mt-2" id="location_name" readOnly value={`${ticketRecord.listings_name} ${ticketRecord.internal_name ? `- ${ticketRecord.internal_name}` : ''}`} />
                            </div>
                            <div className="col-md-12 mt-3">
                                <label htmlFor="emailuser">User</label>
                                <Select
                                    value={userDefaultEmail}
                                    name="email[]"
                                    isClearable={true}
                                    closeMenuOnSelect={false}
                                    options={userEmail ?? []}
                                    isMulti={true}
                                    onChange={(e) => selectUserEmail(e)}
                                    className="basic-multi-select react-select mt-2"
                                    id="email"
                                    required
                                />
                            </div>
                            <div className="col-md-12 mt-3">
                                <label htmlFor="status">Location Status<sup className="text-danger fw-bold"> *</sup></label>
                                <select name="status" id="status" className="form-select form-control mt-2" required >
                                    <option value="">Choose Status</option>
                                    <option value="sync" >Sync</option>
                                    <option value="processing" >In Process</option>
                                    <option value="not_sync" >Not Sync</option>
                                    <option value="opted_out">Opted Out</option>
                                </select>
                                <div className="invalid-feedback">Please select status.</div>
                            </div>
                            <div className="col-md-12 mt-3">
                                <label htmlFor="status">Message<sup className="text-danger fw-bold"> *</sup></label>
                                <textarea name="message" id="message" cols="30" rows="7" className="form-control mt-2" required ></textarea>
                                <div className="invalid-feedback">Please type message.</div>
                            </div>
                            <div className="col text-end my-4">
                                <button type="button" className="btn btn-primary" onClick={(e) => saveLocationTicket(e)} disabled={tinyloader}>
                                    {tinyloader ?
                                        <div className="spinner-border spinner-border-sm mx-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> : 'Submit'
                                    }
                                </button>
                                <button type="button" className="btn btn-outline-danger ms-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => initialFormState('locationTicketForm')}>Close</button>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <h4 className="fw-bold">Latest Activity</h4>
                            <div className="col-md-12 border p-3 rounded overflow-auto" style={{ height: "450px" }}>
                                {ticketRecord && ticketRecord.chat_activity && ticketRecord.chat_activity.length > 0 ? ticketRecord.chat_activity.map((ele, key) => {
                                    return (
                                        <div className="col border p-2 rounded my-3" key={key}>
                                            <div className="col-md-12 d-flex justify-content-between border-bottom mb-2 py-2">
                                                <h6 className="fw-bold">{ele && ele.activity_user && `${ele.activity_user.first_name} ${ele.activity_user.last_name}`}</h6>
                                                <span className={`badge rounded-pill py-2 bg-${ele.status === 'sync' ? 'success' : ele.status === 'not_sync' ? 'danger' : ele.status === 'processing' ? 'warning' : 'primary'}`}>{ele && _.capitalize(_.replace(ele.status, '_', ' '))}</span>
                                                <span>{dateTimeFormat(ele.updated_at)}</span>
                                            </div>
                                            <div className="col-md-12 ">
                                                {ele.message}
                                            </div>
                                        </div>
                                    )
                                })
                                    :
                                    <div className="col d-flex justify-content-center align-items-center" style={{ height: "450px" }}>

                                        <h2 className="fw-bold">No activity found</h2>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </form>
            </Elements.ModalSection>
        </>
    )
}

export default ManageBusinessListing
