import { loadingData, toastNotify } from "./Helper"
export const ENDPOINT = process.env.REACT_APP_ENDPOINT
export const ASSET_ENDPOINT = process.env.REACT_APP_ASSET_ENDPOINT

export const LOGIN = "/login"
export const CONFIRM_PASSWORD = "/confirm-password"
export const FORGOT_PASSWORD = "/forgot-password"
export const RESET_PASSWORD = "/reset-password"
export const LOGOUT = "/logout"
export const GET_AUTH_USER_INFO = "/get-auth-user-info"

export const ADMIN_LIST = "/super-admin-list"
export const USER_LIST = "/user-list"
export const GET_BLOG = "/get-blog"
export const GET_URL = "/get-url"
export const CONTACT_US = "/contact-us"
export const GET_PRICING = "/get-pricing"
export const ADD_USER_SUBSCRIBE = "/add-user-subscribe"
export const ONBOARDING_FORM = "/onboarding-form"
export const ONBOARDING_LIST = '/onboarding-list'
export const ONBOARDING_DETAIL = '/onboarding-detail'
export const ONBOARDING_DELETE = '/delete-onboarding'
export const ONBOARDING_STATUS = '/onboarding-status-change'
export const BUSINESS_SCAN = "/business-scan"
export const SEO_REQUEST = "/request-seo"
export const PPC_REQUEST = "/request-ppc"


export const SALES_AGENT_LIST = "/sales-agent-details"
export const ADD_SALES_AGENT = "/add-sales-agent"
export const UPDATE_SALES_AGENT = "/update-sales-agent"
export const GET_AGENT_LOCATION = "/get-agent-location"
export const DELETE_SALES_AGENT = "/delete-sales-agent"

export const VIEW_TICKET = "/list-ticket"
export const CREATE_TICKET = "/create-ticket"
export const DELETE_TICKET = "/delete-ticket"
export const UPDATE_TICKET = "/update-ticket"
export const ADMIN_TICKET_REPLY = "/admin-ticket-reply"
export const GET_TICKET_STAFF = "/get-ticket-staff"

export const REQUEST_CALL_LIST = "/request-call-list"
export const REQUEST_CALL = "/request-call"
export const DELETE_REQUEST_CALL = "/delete-request-call"
export const UPDATE_REQUEST_CALL = "/update-request-call"

export const REQUEST_DEMO_LIST = "/request-demo-list"
export const ADD_REQUEST_DEMO = "/add-request-demo"
export const DELETE_REQUEST_DEMO = "/delete-request-demo"
export const UPDATE_REQUEST_DEMO = "/update-request-demo"

export const EXPORT = "/export"

export const RATE_US_LIST = "/rate-us-list"

export const VIEW_WEBSITE_SUPPORT = "/list-website-support"
export const ADD_NEW_WEBSITE_SUPPORT = "/add-new-website-support"
export const DELETE_WEBSITE_SUPPORT = "/delete-website-support"
export const UPDATE_WEBSITE_SUPPORT_STATUS = "/update-website-support-status"

export const FAQ_LIST = "/faq-list"
export const Add_FAQS = "/add-faq"
export const UPDATE_FAQ = "/update-faq"
export const DELETE_FAQ = "/delete-faq"

export const PLAN_PRICE_LISTING = "/plan-price-details"
export const ADD_PRICE_LISTING = "/add-plans-price"
export const UPDATE_PRICE_LISTING = "/update-plans-price"
export const DELETE_PRICE_LISTING = "/delete-plan-price"
export const PERMISSION_LISTING = "/get-permission"

export const LIST_PROMO_CODE = "/promo-code-details"
export const ADD_PROMO_CODE = "/add-promo-code"
export const UPDATE_PROMO_CODE = "/update-promo-code"
export const DELETE_PROMO_CODE = "/delete-promo-code"

export const BOOK_KEEPING_LIST = "/book-keeping"
export const BOOK_KEEPING_COUNT = "/book-keeping-count"
export const MANAGE_BUSINESS_LIST = "/location-list"
export const SOCI_MANAGE_BUSINESS_LIST = "/soci/business-location-list"
export const SOCI_SOCIAL_BUSINESS_LIST = "/soci/social-location-list"
export const LOCATION_WITH_LOCATION_ID = "/location-by-location-id"
export const DELETE_LOCATION = "/delete-location"

export const SUBSCRIBERS = "/subscribers"
export const DELETE_SUBSCRIBER = "/delete-subscribers"

export const ADMIN_CHANGE_PASSWORD = "/change-admin-password"
export const UPDATE_ADMIN_PROFILE = "/update-admin-profile"

export const USER_ACTIVITY_LOG = "/user-activity-log"
export const ACTIVITY_LOG = "/activity-log"
export const GET_LOCATION_UNIQUE_URL = "/get-location-unique-url"
export const ADD_DYANMIC_URL = "/add-dynamic-url"
export const ADMIN_REPORT = "/admin-report"

export const ADD_DROPDOWN_DATA = "/get-binding-data"
export const VIEW_MAPPING = "/view-mapping"
export const GET_LOCATION = "/get-location"
export const ADD_USER = "/add-user"
export const EDIT_USER = "/edit-user"
export const DELETE_USER = "/delete-user"
export const USER_PERMISSION = "/user-permission"
export const DIRECT_LOGIN = "/direct-login"
export const GET_LOCATION_PORT = "/get-location-port"
export const IMPRINT_UPDATE = "/imprint-update"
export const PREVIEW_CARD_SUBMIT = "/preview-card-submit"
export const GET_BUSINESS_CARD_LIST = "/get-business-card-list"
export const GET_IMPRINTS_LIST = "/get-imprints-list"
export const GET_CONVERT_IMAGE_TO_BASE64 = "/convert-image-to-base64"

export const ADD_LOCATION = "/add-location"
export const EDIT_LOCATION = "/update-location"
export const USER_DIGITAL_BUSINESS = "/user-digital-business"
export const USER_DIGITAL_BUSINESS_LISTING = "/user-digital-business-listing"

//export const USER_DIGITAL_BUSINESS_DATALISTING = "/user-digital-business-datalisting"

export const DASHBOARD_OVERVIEW = "/dashboard-overview";
export const SOCI_DASHBOARD = "/soci-dashboard";
export const SOCI_SOCIAL_INSIGHT = "/soci/view-business-dashboard";
export const VIEW_BUSINESS_DASHBOARD = "/soci/view-business-dashboard";
export const SOCI_LOCATION_DASHBOARD = "/soci-location-dashboard";
export const SENTIMENT_ANALYSIS = "/soci/get-sentiment-analysis";
export const DASHBOARD_LISTING = "/dashboard-listing";
export const SIGNUP = "/signup";
export const GET_STRIPE_SECRET = "/get-client-secret";
export const APPLY_PROMO_CODE = "/apply-promo-code";
export const INVOICE_LIST = "/invoice-list";
export const GET_SENTIMENT = "/get-sentiment";
export const UPDATE_MAPPING = "/update-mapping";
export const COMMON_COMPETITIVE = "/common-competitve";
export const GET_SEARCH_REPORT = "/get-search-report";
export const GET_REVIEW_COUNT = "/review-count";
export const GET_REVIEW_LIST = "/review-list";
export const GET_REVIEW_COMMENT = "/review-comment";
export const POST_REPLY_COMMENT = "/reply-comment";
export const POST_EDIT_COMMENT = "/edit-comment";
export const GET_DELETE_COMMENT = "/delete-comment";
export const GET_SOCIAL_DATA = "/social-data";
export const GET_REVIEW_LIST_PDF = "/review-list-pdf";

export const CED_LOGIN_API = "/cedlogin";
export const GENERATE_CED_LOGIN_TOKEN = "/getDummyToken";

export const SETTINGS_DATA = "/get-settings";
export const UPDATE_SETTING = "/update-settings";

export const GMB_LOCATION = "/get-gmb-location";
export const SYNC_GMBSTORE_ID = "/sync-gmb-store-id";
export const LOCATION_VERIFICATION_OPTION = "/gmb-verification-options";
export const LOCATION_VERIFICATION_PIN = "/send-verification-pin";
export const VERIFY_PIN = "/verify-pin";
export const ACTIVE_LOCATION = "/get-active-location";
export const CREATE_UPDATE_LOCATION_TICKET = "/update-location-ticket";
export const USER_ASSIGN_LOCATION = "/user-assign-location";
export const OPTINOPTOUT = "/optin-optout";
export const UPGRADE_PLAN = "/upgrade-plan";
export const CANCEL_SUBSCRIPTION = "/cancel-subscription";
export const CHANGE_SERVICE = "/change-service-provider";
export const ADD_SOCI_LOCATION = "/soci/add-new-soci-location"
export const VIEW_LOCATION_DETAIL = "/soci/view-location-detail"
export const BUSINESS_NETWORK_LIST = "/soci/business-networks-list"
export const GET_COMPETITORS_INFO = "/soci/get-competitors"
export const DELETE_PROJECT = "/soci/delete-project"
export const RESTORE_DELETED = "/soci/restore-deleted-project"

export const REVIEW_SUMMARY_DATA = "/soci/get-review-responses"

export const SOCI_GMB_GROUP_ID = 64331
export const SOCI_SOCIAL_GROUP_ID = 64332

export const fetchData = async (
  url,
  method,
  data,
  token,
  process,
  res,
  processImg = false,
  abortSignal = false,
  showLoader = true
) => {
  loadingData(showLoader);

  let headers = {
    Accept: "application/json",
  };

  if (process) {
    headers = { ...headers };
  } else {
    headers = { ...headers, "Content-Type": "application/json" };
  }

  if (token) {
    let TOKEN = localStorage.getItem("amazio-token");
    headers = { ...headers, Authorization: "Bearer " + TOKEN };
  }

  let request = {
    method: method,
    headers: headers,
  };

  if (abortSignal) {
    request = { ...request, signal: abortSignal };
  }

  if (data) {
    request = { ...request, body: process ? data : JSON.stringify(data) };
  }

  await fetch(`${ENDPOINT}${url}`, request)
    .then((response) =>
      processImg === "text"
        ? response.text()
        : processImg === "blob"
          ? response.blob()
          : response.json()
    )
    .then((json) => {
      loadingData();
      if (json.message === "Unauthenticated.") {
        localStorage.removeItem("amazio-token");
        window.location.href = "/log-in";
      } else if (!json.records) {
        toastNotify(json);
        res(json);
      } else {
        res(json);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
