import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import ReactDom from 'react-dom';
import DatePicker from "react-datepicker";
import _, { now, split } from "lodash";
import TagInputComponent from '../../components/AddSociLocation/TagInput';
import makeAnimated from 'react-select/animated';
import DayComponent from '../../components/AddSociLocation/DayComponent';
import { fetchData, ADD_SOCI_LOCATION, ADD_DROPDOWN_DATA, DELETE_PROJECT } from '../../components/Service';
import timezone from '../../components/AddSociLocation/Timezone'
import { reactSelectValidation, validateForm, state, sociPaymentMethods } from '../../components/Helper';
import ImageUploadSoci from './ImageUploadSoci';
import ImageCropper from "../../components/ImageCropper";
import SpecialHours from './SpacialHours';
import { Context } from '../../components/Context';
import * as Elements from "../../components/Elements";
const animatedComponents = makeAnimated();


const SociLocationForm = (props) => {

    const [phoneState, setPhoneState] = useState(false);
    const [locationState, setLocationState] = useState(false);
    const [websiteState, setWebsiteState] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [tinyloader, setTinyloader] = useState(false);
    const [openingDate, setOpeningDate] = useState()
    const [hours, setHours] = useState({});
    const [selectedCountry, setSelectedCountry] = useState([])
    const [selectedStatus, setSelectedStatus] = useState([''])
    const [selectedTimezone, setSelectedTimezone] = useState([])
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([])
    const [selectedLanguages, setSelectedLanguages] = useState([])
    const [keywords, setKeywords] = useState([])
    const [services, setServices] = useState([])
    const [brands, setBrands] = useState([])
    const [selectedState, setSelectedState] = useState('');
    const [selectedPriceRange, setSelectedPriceRange] = useState("omit");
    const [category, setCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [imageName, setImageName] = useState('');
    const [allImage, setAllImage] = useState('');
    const [context] = useContext(Context)
    const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const [specialHours, setSpecialHours] = useState([]);

    useEffect(() => {

        if (props.editId && (props.records?.listing_name_network?.location_facebook || props.records?.listing_name_network?.location_gmb)) {
            setLocationState(true)
        }

        if (props.editId && props.records?.phone_network && (props.records.phone_network?.phone_facebook || props.records.phone_network?.phone_gmb || props.records.phone_network?.phone_apple || props.records.phone_network?.phone_bing || props.records.phone_network?.phone_nextdoor || props.records.phone_network?.phone_yelp)) {
            setPhoneState(true)
        }

        if (props.editId && props.records?.website_network && (props.records.website_network?.website_url_facebook || props.records.website_network?.website_url_bing || props.records.website_network?.website_url_gmb)) {
            setWebsiteState(true)
        }

        if (props.editId && props.records && props.records.keywords) {
            setKeywords(split(props.editId && props.records ? props.records.keywords || "" : "", ','));
        } else {
            setKeywords([]);
        }

        if (props.editId && props.records && props.records.services) {
            setServices(split(props.editId && props.records ? props.records.services || "" : "", ','));
        } else {
            setServices([]);
        }

        if (props.editId && props.records && props.records.brands) {
            setBrands(split(props.editId && props.records ? props.records.brands || "" : "", ','));
        } else {
            setBrands([]);
        }

        setSelectedState(props.records?.state || "");

        if (props.editId && props.records?.opening_status) {
            const selected = colourOptions.find(option => option.value === props.records.opening_status);
            setSelectedStatus(selected || []);
        }

        if (props.editId && props.records?.opening_date && props.records.opening_date !== '0000-00-00') {
            const formattedDate = new Date(props.records?.opening_date);
            setOpeningDate(formattedDate);
        }

        if (props.editId && props.records?.country) {
            const selected = countryOptions.find(option => option.value === props.records.country);
            setSelectedCountry(selected || []);
        }

        if (props.editId && props.records?.languages) {
            const selected = languages.find(option => option.value === props.records.languages);
            setSelectedLanguages(selected || []);
        }

        if (props.editId && props.records?.category_ids) {
            var ids = props.editId && props.records?.category_ids.split(',').map(Number)
            const selected = category.filter(option => ids.includes(option.value));
            setSelectedCategory(selected || []);
        }

        if (props.editId && Array.isArray(props.records?.payment_forms)) {
            const selected = sociPaymentMethods.filter(option =>
                props.records.payment_forms.includes(option.value)
            );
            setSelectedPaymentMethods(selected || []);
        }

        if (props.editId && props.records?.location_timezone) {
            const selected = timezone.find(option => option.value === props.records.location_timezone);
            setSelectedTimezone(selected || []);
        }

        if (props.editId && props.records?.price_range) {
            setSelectedPriceRange(props.records.price_range);
        }

        if (props.editId && props?.records?.business_hours) {
            setHours(props?.records?.business_hours)
        }

        if (props.editId && props?.records?.network_images) {
            setAllImage(props?.records?.network_images)
        }

        if (props.editId && props?.records?.holiday_hours) {
            const parsedData = typeof props.records.holiday_hours === "string"
                ? JSON.parse(props.records.holiday_hours)
                : props.records.holiday_hours;

            setSpecialHours(parsedData);
        }

        if (!props.editId) {
            setLocationState(false)
            setPhoneState(false)
            setWebsiteState(false)
            setOpeningDate()
            setSelectedState('')
            setSelectedStatus([])
            setSelectedCountry([])
            setSelectedLanguages([]);
            setSelectedCategory([]);
            setSelectedPaymentMethods([]);
            setSelectedTimezone([]);
            setSelectedPriceRange("omit");
            setHours({});
            setAllImage('');
        }

    }, [props.editId, props.records])

    useEffect(() => {
        fetchData(`${ADD_DROPDOWN_DATA}?select=soci_category`, 'GET', '', true, false, (res) => {
            if (res && res.records && res.records.length > 0) {
                setCategory(res.records)
            }
        }, false, false, false);
    }, [])

    const deleteLocation = (projectId) => {
        fetchData(DELETE_PROJECT, 'DELETE', { project_id: projectId }, true, false, (res) => {
            setTinyloader(false)
            if (res.success) {
                document.querySelector('#confModal [data-bs-dismiss="modal"]').click();
                if (props.refresh) props.refresh(now)
            } else {
                document.querySelector('#confModal [data-bs-dismiss="modal"]').click();
            }
        })
    }


    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: "white" }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = data.color;
            return { ...styles, backgroundColor: isDisabled ? undefined : isSelected, color: isDisabled ? "#ccc" : isSelected ? getContrastYIQ(color) : color, };
        },
        input: (styles) => ({ ...styles, ...dot() }),
        placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };

    // Function to generate a small colored dot
    const dot = (color = "transparent") => ({
        alignItems: "center",
        display: "flex",

        ":before": {
            content: '""',
            display: "block",
            width: 10,
            height: 10,
            borderRadius: "50%",
            backgroundColor: color,
            marginRight: 10,
        },
    });



    // Function to check contrast and return white or black text color
    const getContrastYIQ = (hex) => {
        hex = hex.replace(/^#/, "");
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq >= 128 ? "black" : "white"; // High contrast ensures readability
    };

    // Location Status options
    const colourOptions = [
        { value: "open", label: "Open", color: "#29cc91" },
        { value: "temporarily_closed", label: "Temporarily Closed", color: "#e67e22" },
        { value: "permanently_closed", label: "Permanently Closed", color: "#ea5b59" },
        { value: "always_open", label: "Always Open", color: "#57b528" },
        { value: "selected_hours", label: "Selected Hours", color: "#2bb9ff" },
        { value: "no_hours", label: "No Hours", color: "#f0f" },
        { value: "not_selected", label: "Not Selected", color: "#101112" },
    ];

    // Contry options
    const countryOptions = [
        { value: "US", label: "🇺🇸 United States" },
    ];

    const languages = [
        { value: "en_US", label: "English" },
    ];


    const handleTimeChange = (day, timeSlots, hourType) => {
        setHours((prevData) => ({
            ...prevData,
            [day]: { timeSlots, hourType },
        }));
        // console.log(hours, "Days")
    };

    const handleSubmitForm = (e) => {
        e.preventDefault();
        setTinyloader(true)
        let formdata = new FormData(document.getElementById('locationForm'));
        formdata.append('hours', JSON.stringify(hours))
        formdata.append('special_hours', JSON.stringify(specialHours))
        formdata.append('keywords', keywords)
        formdata.append('services', services)
        formdata.append('brands', brands)
        formdata.append('allImages', JSON.stringify(allImage))

        if (!validateForm(e)) {
            setTinyloader(false);
            const firstErrorInput = document.querySelector(".is-invalid, .error");
            if (firstErrorInput) {
                firstErrorInput.scrollIntoView({ behavior: "smooth", block: "center" });
                firstErrorInput.focus();
            }
            return;
        }

        const project_id = props?.records?.project_id
        const URL = props.editId && project_id ? `${ADD_SOCI_LOCATION}/${project_id}` : ADD_SOCI_LOCATION;
        fetchData(URL, 'POST', formdata, true, true, (res) => {
            setTinyloader(false)
            // console.log(res.success);
            if (res.success) {
                if (props.refresh) props.refresh(now)
                document.querySelector('#locationForm [data-bs-dismiss="modal"]').click();
            }
        }, false, false, false)

    }

    return (
        <>
            <style>
                {`  .react-select-wrapper span[aria-live]+div:focus { border-color : #dee2e6 !important; outline:none !important; box-shadow: none !important; }
                    .react-select-wrapper span[aria-live]+div:has(input:focus) {color: var(--dark-blue); background-color: #fff; border-color: rgba(var(--dark-blue-rgb), 0.5); outline: 0; box-shadow: 0 0 0 0.25rem rgba(var(--dark-blue-rgb), 0.15);}
                    .add-location-modal-body .form-label { line-height: 1; font-size: 14px; color: #555; font-weight: 500;}
                    .add-location-modal-body .form-control { font-size: 14px;}
                `}
            </style>

            <form className={`needs-validation p-3 ${pageLoader ? 'd-none' : 'd-block'}`} id="locationForm">
                <div className="add-location-modal-body">
                    <div className="row g-3">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label">Location Status<strong className="text-danger">*</strong>:</label>
                                <Select
                                    placeholder={'--Select Location Status--'}
                                    value={selectedStatus}
                                    options={colourOptions}
                                    isMulti={false}
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                    onChange={(newValues) => { reactSelectValidation(newValues, "opening_status", false); setSelectedStatus(newValues ?? []) }}
                                    isSearchable
                                    name="opening_status"
                                    id="opening_status"
                                    className='basic-multi-select react-select required'
                                    // required="true"
                                    styles={colourStyles}
                                />
                                <div className="invalid-feedback">The location status field is required.</div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label w-100">Opening Date:</label>
                                <div className="d-flex flex-column position-relative">
                                    <DatePicker
                                        placeholderText="No Data Found"
                                        popperPlacement="bottom"
                                        className="form-control pe-5"
                                        name="opening_date"
                                        id="opening_date"
                                        selected={openingDate}
                                        maxDate={new Date()}
                                        dateFormat="MM-dd-yyyy"
                                        dropdownMode="select"
                                        onChange={(date) => setOpeningDate(date)}
                                    />
                                    <span className="input-group-text pe-none m-1 position-absolute top-0 bottom-0 end-0 rounded-start border-0 text-secondary px-2 bg-transparent">
                                        <i class="bi bi-calendar"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label w-100">Location Name<strong className="text-danger">*</strong>:</label>
                                <div className="d-flex gap-2  position-relative align-items-start">
                                    <div className="position-relative flex-grow-1">
                                        <input type="text" name='location_name' id="location_name" defaultValue={props.editId && props.records ? props.records.listings_name || "" : ""} placeholder={"Enter location name"} className="form-control" required />
                                        <div className="invalid-feedback">The location name field is required</div>
                                    </div>
                                    {!locationState ?
                                        <button onClick={() => setLocationState(!locationState)} type="button" className="input-group-text top-0 bottom-0 end-0 rounded-start border" style={{ paddingInline: "10px", paddingBlock: '4px' }} >
                                            <i className="bi bi-plus-lg"></i>
                                        </button> :
                                        <button onClick={() => setLocationState(!locationState)} type="button" className="input-group-text top-0 bottom-0 end-0 rounded-start border" style={{ paddingInline: "10px", paddingBlock: '4px' }} >
                                            <i className="bi bi-dash-lg"></i>
                                        </button>
                                    }
                                </div>
                                {locationState &&
                                    <div className="d-flex flex-column gap-2 ps-2 mt-2">
                                        <div className=" position-relative align-items-start">
                                            <input type="text" name='location_facebook' defaultValue={props.editId && props.records ? props.records?.listing_name_network?.location_facebook || "" : ""} placeholder={"Location name for facebook"} className="form-control" />
                                            <span class="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent"><i className={`bi bi-facebook`}></i></span>
                                        </div>
                                        <div className=" position-relative align-items-start">
                                            <input type="text" name='location_gmb' defaultValue={props.editId && props.records ? props.records?.listing_name_network?.location_gmb || "" : ""} placeholder={"Location name for gmb"} className="form-control" />
                                            <span class="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent"><i className={`bi bi-google`}></i></span>
                                        </div>
                                    </div>
                                }
                                <div className="invalid-feedback">The location name field is required</div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label w-100">PC number (Business Alias)<strong className="text-danger"></strong>:</label>
                                <div className="d-flex gap-2  position-relative align-items-start">
                                    <div className="position-relative flex-grow-1">
                                        <input type="text" name='alias_name' id="alias_name" defaultValue={props.editId && props.records ? props.records.alias_name || "" : ""} placeholder={"Enter alias name"} className="form-control"  />
                                        <div className="invalid-feedback">The alias name field is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="address_line_1" class="form-label w-100">Address Line 1<strong className="text-danger">*</strong>:</label>
                                <input type="text" class="form-control" id="address_line_1" name="address_line_1" defaultValue={props.editId && props.records ? props.records.address_line_1 || "" : ""} placeholder="No Data" required />
                                <div className="invalid-feedback">The address Line 1 field is required</div>
                            </div>
                        </div>

                        <div className="col-lg-6 d-none">
                            <div className="form-group">
                                <label htmlFor="address_line_1" class="form-label w-100">Group Id<strong className="text-danger">*</strong>:</label>
                                <input type="text" class="form-control" id="group_id" name="group_id" value={props.group_id} placeholder="No Data" required />
                                <div className="invalid-feedback">The address Line 1 field is required</div>
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="address_line_2" class="form-label w-100">Address Line 2:</label>
                                <input type="text" class="form-control" name="address_line_2" id="address_line_2" defaultValue={props.editId && props.records ? props.records.address_line_2 || "" : ""} placeholder="No Data" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label">Country<strong className="text-danger">*</strong>:</label>
                                <Select
                                    placeholder={'--Select Country--'}
                                    value={selectedCountry}
                                    options={countryOptions}
                                    isMulti={false}
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                    onChange={(e) => { reactSelectValidation(e, "country", false); setSelectedCountry(e ?? []) }}
                                    isSearchable
                                    name="country"
                                    id="country"
                                    className='basic-multi-select react-select required'
                                    required={true}
                                />
                                <div className="invalid-feedback">The country field is required.</div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="state_province" class="form-label">State/Province<strong className="text-danger">*</strong>:</label>
                                <select className="form-select scan-select px-2" id="state_province" name="state_province" value={selectedState} onChange={(e) => setSelectedState(e.target.value)} placeholder="State/Province name" required>
                                    <option value="">--Select State--</option>
                                    {Object.keys(state).map((ele, num) => <option value={ele} key={num}>{state[ele]}</option>)}
                                </select>
                                <div className="invalid-feedback">The state field is required</div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="city" class="form-label">City<strong className="text-danger">*</strong>:</label>
                                <input type="text" class="form-control" name="city" id="city" defaultValue={props.editId && props.records ? props.records.city || "" : ""} placeholder="Enter city name" required />
                                <div className="invalid-feedback">The city field is required.</div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="postal_code" class="form-label">Postal Code<strong className="text-danger">*</strong>:</label>
                                <input type="text" class="form-control" id="postal_code" name="postal_code" defaultValue={props.editId && props.records ? props.records.postal_code || "" : ""} placeholder="Enter postal code" required />
                                <div className="invalid-feedback">The postal code field is required.</div>
                            </div>
                        </div>
                        {/* <div className="col-md-12">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="service_area_only" id="service_area_only" defaultChecked={props.editId && props.records ? props.records.service_area_only || "" : ""}/>
                            <label className="form-check-label" htmlFor="service_area_only">
                                Service Area Only
                            </label>
                        </div>
                    </div> */}
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="services" class="form-label">Services:</label>
                                <TagInputComponent name="services" value={services} setValue={setServices} placeholder={'Enter Services'} />
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="service_areas" class="form-label">Service Areas</label>
                            <input type="text" class="form-control" name="service_areas[]" id="service_areas" defaultValue={props.editId && props.records ? props.records.service_areas || "" : ""} placeholder="Enter your service areas" />
                        </div>
                    </div> */}
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label w-100">Phone<strong className="text-danger">*</strong>:</label>
                                <div className="d-flex gap-2  position-relative align-items-start">
                                    <div className="position-relative flex-grow-1">
                                        <input type="text" onKeyDown={(e) => {if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {e.preventDefault();}}} maxLength={15} minLength={8} name='phone' id="phone" defaultValue={props.editId && props.records ? props.records.phone || "" : ""} placeholder="Enter phone number" className="form-control" required />
                                        <div className="invalid-feedback">The phone field is required</div>
                                    </div>
                                    {
                                        !phoneState ?
                                            <button onClick={() => setPhoneState(!phoneState)} type="button" className="input-group-text top-0 bottom-0 end-0 rounded-start border" style={{ paddingInline: "10px", paddingBlock: '4px' }} >
                                                <i className="bi bi-plus-lg"></i>
                                            </button>
                                            :
                                            <button onClick={() => setPhoneState(!phoneState)} type="button" className="input-group-text top-0 bottom-0 end-0 rounded-start border" style={{ paddingInline: "10px", paddingBlock: '4px' }} >
                                                <i className="bi bi-dash-lg"></i>
                                            </button>}
                                </div>
                                {phoneState &&
                                    <div className="d-flex flex-column gap-2 ps-2 mt-2">
                                        <div className="position-relative align-items-start">
                                            <input type="text" onKeyDown={(e) => {if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {e.preventDefault();}}} maxLength={15} minLength={8} name='phone_facebook' placeholder={"Enter phone number"} defaultValue={props.editId && props.records && props.records.phone_network ? props.records.phone_network.phone_facebook || "" : ""} className="form-control" />
                                            <span class="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent"><i className={`bi bi-facebook`}></i></span>
                                        </div>
                                        <div className="position-relative align-items-start">
                                            <input type="text" onKeyDown={(e) => {if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {e.preventDefault();}}} maxLength={15} minLength={8} name='phone_gmb' placeholder={"Enter phone number"} defaultValue={props.editId && props.records && props.records.phone_network ? props.records.phone_network.phone_gmb || "" : ""} className="form-control" />
                                            <span class="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent"><i className={`bi bi-google`}></i></span>
                                        </div>
                                        <div className="position-relative align-items-start">
                                            <input type="text" onKeyDown={(e) => {if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {e.preventDefault();}}} maxLength={15} minLength={8} name='phone_apple' placeholder={"Enter Phone number"} defaultValue={props.editId && props.records && props.records.phone_network ? props.records.phone_network.phone_apple || "" : ""} className="form-control" />
                                            <span class="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent"><i className={`bi bi-apple`}></i></span>
                                        </div>
                                        <div className="position-relative align-items-start">
                                            <input type="text" onKeyDown={(e) => {if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {e.preventDefault();}}} maxLength={15} minLength={8} name='phone_bing' placeholder={"Enter phone number"} defaultValue={props.editId && props.records && props.records.phone_network ? props.records.phone_network.phone_bing || "" : ""} className="form-control" />
                                            <span class="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent"><i className={`bi bi-bing`}></i></span>
                                        </div>
                                        <div className="position-relative align-items-start">
                                            <input type="text" onKeyDown={(e) => {if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {e.preventDefault();}}} maxLength={15} minLength={8} name='phone_nextdoor' placeholder={"Enter phone number"} defaultValue={props.editId && props.records && props.records.phone_network ? props.records.phone_network.phone_nextdoor || "" : ""} className="form-control" />
                                            <span class="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent"><i className={`bi bi-reception-4`}></i></span>
                                        </div>
                                        <div className="position-relative align-items-start">
                                            <input type="text" onKeyDown={(e) => {if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {e.preventDefault();}}} maxLength={15} minLength={8} name='phone_yelp' placeholder={"Enter phone number"} defaultValue={props.editId && props.records && props.records.phone_network ? props.records.phone_network.phone_yelp || "" : ""} className="form-control" />
                                            <span class="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent"><i className={`bi bi-yelp`}></i></span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="fax_phone" class="form-label">Fax:</label>
                                <input type="text" onKeyDown={(e) => {if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {e.preventDefault();}}} maxLength={15} minLength={8} class="form-control" id="fax" name="fax" defaultValue={props.editId && props.records ? props.records.fax || "" : ""} placeholder="Enter fax phone" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="email_address" class="form-label">Email<strong className="text-danger">*</strong>:</label>
                                <input type="email" class="form-control" id="email_address" name="email_address" defaultValue={props.editId && props.records ? props.records.email || "" : ""} placeholder={"Enter email Id"} required />
                                <div className="invalid-feedback">The email field is required.</div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label w-100">Website URL:</label>
                                <div className="d-flex gap-2  position-relative align-items-start">
                                    <div className="position-relative flex-grow-1">
                                        <input type="url" name='website_url' placeholder={"Enter website url"} defaultValue={props.editId && props.records ? props.records.website_url || "" : ""} className="form-control" />
                                        <div className="invalid-feedback">The website url field is required.</div>
                                    </div>
                                    {
                                        !websiteState ?
                                            <button onClick={() => setWebsiteState(!websiteState)} type="button" className="input-group-text top-0 bottom-0 end-0 rounded-start border" style={{ paddingInline: "10px", paddingBlock: '4px' }} >
                                                <i className="bi bi-plus-lg"></i>
                                            </button>
                                            :
                                            <button onClick={() => setWebsiteState(!websiteState)} type="button" className="input-group-text top-0 bottom-0 end-0 rounded-start border" style={{ paddingInline: "10px", paddingBlock: '4px' }} >
                                                <i className="bi bi-dash-lg"></i>
                                            </button>}
                                </div>
                                {websiteState &&
                                    <div className="d-flex flex-column gap-2 ps-2 mt-2">
                                        <div className="position-relative align-items-start">
                                            <input type="url" name='website_url_facebook' placeholder={"Enter facebook url"} defaultValue={props.editId && props.records && props.records.website_network ? props.records.website_network.website_url_facebook || "" : ""} className="form-control" />
                                            <span class="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent"><i className={`bi bi-facebook`}></i></span>
                                        </div>
                                        <div className="position-relative align-items-start">
                                            <input type="url" name='website_url_gmb' placeholder={"Enter gmb url"} defaultValue={props.editId && props.records && props.records.website_network ? props.records.website_network.website_url_gmb || "" : ""} className="form-control" />
                                            <span class="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent"><i className={`bi bi-google`}></i></span>
                                        </div>
                                        <div className="position-relative align-items-start">
                                            <input type="url" name='website_url_bing' placeholder={"Enter bing url"} defaultValue={props.editId && props.records && props.records.website_network ? props.records.website_network.website_url_bing || "" : ""} className="form-control" />
                                            <span class="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent"><i className={`bi bi-bing`}></i></span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label">Timezone:</label>
                                <Select
                                    placeholder={'--Select Timezone--'}
                                    value={selectedTimezone}
                                    options={timezone}
                                    isMulti={false}
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                    onChange={(e) => { reactSelectValidation(e, "timezone", false); setSelectedTimezone(e ?? []) }}
                                    isSearchable
                                    className='basic-multi-select react-select required'
                                    name="timezone"
                                    id="timezone"
                                // required={true}
                                />
                                <div className="invalid-feedback">The timezone field is required.</div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="long_description" class="form-label">Long Description:</label>
                                <textarea class="form-control" id="long_description" name="long_description" maxLength={500} placeholder="Enter long description" defaultValue={props.editId && props.records ? props.records.long_description || "" : ""}></textarea>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="short_description" class="form-label">Short Description: </label>
                                <textarea class="form-control" id="short_description" name="short_description" maxLength={100} placeholder="Enter short description" defaultValue={props.editId && props.records ? props.records.short_description || "" : ""}></textarea>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="keywords" class="form-label">Keywords:</label>
                                <TagInputComponent name="keywords" value={keywords} setValue={setKeywords} placeholder={'Enter Keywords'} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label">Payment Forms:</label>
                                <Select
                                    placeholder={'--Select--'}
                                    value={selectedPaymentMethods}
                                    options={sociPaymentMethods}
                                    isMulti={true}
                                    isClearable={true}
                                    closeMenuOnSelect={false}
                                    onChange={(e) => { reactSelectValidation(e, "timezone", true); setSelectedPaymentMethods(e ?? []) }}
                                    isSearchable
                                    className='basic-multi-select react-select required'
                                    name="payment_forms[]"
                                    id="payment_forms"
                                    required={false}
                                />
                                {/* <div className="invalid-feedback">The payment forms field is required.</div> */}
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label">Brands:</label>
                                <TagInputComponent name="brands" value={brands} setValue={setBrands} placeholder={'Enter Brands'} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label">Price Range: </label>
                                <div className="d-flex flex-wrap gap-3 py-2">
                                    {["$", "$$", "$$$", "$$$$", "omit", "N/A"].map((price) => (
                                        <div className="form-check" key={price}>
                                            <input
                                                className="form-check-input"
                                                name="price_range"
                                                type="radio"
                                                id={price}
                                                value={price}
                                                checked={selectedPriceRange === price}
                                                onChange={(e) => setSelectedPriceRange(e.target.value)}
                                            />
                                            <label className="form-check-label user-select-none" htmlFor={price}>
                                                {price}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label w-100">Language<strong className="text-danger">*</strong>:</label>
                                <Select
                                    components={animatedComponents}
                                    placeholder={'--Select Language--'}
                                    value={selectedLanguages}
                                    options={languages}
                                    isMulti={false}
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                    onChange={(e) => { reactSelectValidation(e, "languages", false); setSelectedLanguages(e ?? []) }}
                                    isSearchable
                                    name="languages"
                                    id="languages"
                                    className='basic-multi-select react-select required'
                                    required={true}
                                />
                                <div className="invalid-feedback">The language field is required.</div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label">Categories<strong className="text-danger">*</strong>:</label>
                                <Select
                                    placeholder={'-- Select Categories --'}
                                    value={selectedCategory}
                                    options={category ?? []}
                                    isMulti={true}
                                    isClearable={true}
                                    closeMenuOnSelect={false}
                                    onChange={(e) => { reactSelectValidation(e, 'category_ids'); setSelectedCategory(e ?? []) }}
                                    className="basic-multi-select react-select required"
                                    name="category_ids[]"
                                    id="category_ids"
                                    required={true}
                                    isSearchable
                                />
                                <div className="invalid-feedback">The categories field is required</div>
                            </div>
                        </div>


                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput" class="form-label">Social Network URLs:</label>
                                <div className="row g-3">
                                    <div className="col-lg-6">
                                        <div className="position-relative">
                                            <input type="url" class="form-control pe-5" name="social_network_urls[facebook_url]" defaultValue={props.editId && props.records && props.records.social_network_urls ? props.records.social_network_urls.facebook_url || "" : ""} placeholder="Enter Facebook URL" />
                                            <span className="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent">
                                                <i class="bi bi-facebook"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="position-relative">
                                            <input type="url" class="form-control pe-5" name="social_network_urls[instagram_url]" defaultValue={props.editId && props.records && props.records.social_network_urls ? props.records.social_network_urls.instagram_url || "" : ""} placeholder="Enter Instagram URL" />
                                            <span className="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent">
                                                <i class="bi bi-instagram"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="position-relative">
                                            <input type="url" class="form-control pe-5" name="social_network_urls[twitter_url]" defaultValue={props.editId && props.records && props.records.social_network_urls ? props.records.social_network_urls.twitter_url || "" : ""} placeholder="Enter 𝕏 URL" />
                                            <span className="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent">
                                                <i class="bi bi-twitter-x"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="position-relative">
                                            <input type="url" class="form-control pe-5" name="social_network_urls[youtube_url]" defaultValue={props.editId && props.records && props.records.social_network_urls ? props.records.social_network_urls.youtube_url || "" : ""} placeholder="Enter Youtube URL" />
                                            <span className="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent">
                                                <i class="bi bi-youtube"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="position-relative">
                                            <input type="url" class="form-control pe-5" name="social_network_urls[yelp_url]" defaultValue={props.editId && props.records && props.records.social_network_urls ? props.records.social_network_urls.yelp_url || "" : ""} placeholder="Enter Yelp URL" />
                                            <span className="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent">
                                                <i className={`bi bi-yelp`}></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="position-relative">
                                            <input type="url" class="form-control pe-5" name="social_network_urls[linkedin_url]" defaultValue={props.editId && props.records && props.records.social_network_urls ? props.records.social_network_urls.linkedin_url || "" : ""} placeholder="Enter Likedin URL" />
                                            <span className="input-group-text pe-none position-absolute top-0 end-0 rounded-start border-0 text-info-emphasis px-2 bg-transparent">
                                                <i class="bi bi-linkedin"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <div className="bg-light rounded py-2 px-3 fw-medium text-dark mb-2">Business Hours</div>
                                <div className="d-flex gap-2 flex-column">
                                    {weekDays.map((day, index) => (
                                        props.editId && props?.records?.business_hours ?
                                            <DayComponent key={index} day={day} onTimeChange={handleTimeChange} initialData={props.editId && props.records?.business_hours ? props.records.business_hours[day] : undefined} />
                                            :
                                            <DayComponent key={index} day={day} onTimeChange={handleTimeChange} />
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <ImageUploadSoci allImage={allImage} setImageName={setImageName} setAllImage={setAllImage} />
                        </div>

                        <SpecialHours specialHours={specialHours} setSpecialHours={setSpecialHours} />

                    </div>
                </div>
                <div class="modal-footer mt-4 px-0 pb-0">

                    {
                        props?.editId && props.records?.id && context?.auth ? (
                            context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? (
                                context.auth.permission?.find((item) => item.permission === "Manage Location")?.delete === "yes" ? (
                                    <button
                                        type="button"
                                        className="btn btn-danger me-auto rounded-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#confModal"
                                        disabled={tinyloader}
                                    >
                                        Delete Location
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-danger me-auto rounded-2"
                                        disabled
                                        title="No permission"
                                    >
                                        Delete Location
                                    </button>
                                )
                            ) : context.auth.role_id !== 3 ? (
                                <button
                                    type="button"
                                    className="btn btn-danger me-auto rounded-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#confModal"
                                    disabled={tinyloader}
                                >
                                    Delete Location
                                </button>
                            ) : ''
                        ) : ''
                    }
                    <div className="d-flex gap-2">
                        <button type="button" class="btn btn-primary px-4" onClick={(e) => handleSubmitForm(e)} disabled={tinyloader}>
                            {tinyloader ?
                                <div className="spinner-border spinner-border-sm mx-1" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                :
                                <></>
                            }
                            Save
                        </button>
                        <button type="button" class="btn btn-outline-secondary me-auto rounded-2"  data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </form>

            {props.setEditId}

            <React.Fragment>
                {ReactDom.createPortal(<ImageCropper name={imageName} cropImage={allImage} setCropImage={setAllImage} openModal={'location_close'} editId={props.editId} setEditId={props.setEditId} setEditRecord={props.setEditRecord} records={props.records} />, document.getElementById('second_root'))}
            </React.Fragment>

            <React.Fragment>
                {ReactDom.createPortal(<Elements.ConfirmationModal msg="Are you sure to delete this location?" method={() => deleteLocation(props?.editId && props.records?.project_id)} />, document.getElementById('second_root'))}
            </React.Fragment>
        </>
    )
}

export default SociLocationForm
